import React, { useState } from 'react';
import './PartnersBlock.scss';
import Layout from '../../../../components/Layout';
import { ReactComponent as Chainlink } from '../../../../global/images/partners/chainlink.svg';
import { ReactComponent as Polygon } from '../../../../global/images/partners/polygon-logo (1) 1.svg';
import { ReactComponent as Memob } from '../../../../global/images/partners/data-mining.svg';
import { ReactComponent as FileCoin } from '../../../../global/images/partners/filecoin.svg';
import studios from '../../../../global/images/partners/studios.svg';
import studiosHover from '../../../../global/images/partners/logo.svg';
import { ReactComponent as Odin } from '../../../../global/images/partners/odin.svg';
import { ReactComponent as IPFS } from '../../../../global/images/partners/ipfs.svg';
import { ReactComponent as Geodb } from '../../../../global/images/partners/geodb.svg';

const PartnersBlock = () => {
  const [isHover, setIsHover] = useState(false);
  const partners = [
    {
      img: <Chainlink />,
      key: 'chainlink',
    },
    {
      img: <Polygon />,
      key: 'polygon',
    },
    {
      img: <Geodb />,
      key: 'geodb',
    },
    {
      img: <Memob />,
      key: 'memob',
    },
    {
      img: <Odin />,
      key: 'odin',
    },
    {
      img: <FileCoin />,
      key: 'filecoin',
    },
    {
      img: <IPFS />,
      key: 'ipfs',
    },
    {
      img: studios,
      imgHover: studiosHover,
      key: 'studios',
    },
  ];

  return (
    <Layout>
      <div className="partners__container">
        <div className="partners__title">PARTNERS</div>
        <div className="partners__grid">
          {partners.map((i) => (
            <div className={`partners__logo partners__${i.key}`} key={i.key}>
              {i.key === 'studios' ? (
                <img
                  src={isHover ? i.imgHover : i.img}
                  alt="logo"
                  onMouseEnter={() => setIsHover(true)}
                  onMouseLeave={() => setIsHover(false)}
                />
              ) : (
                i.img
              )}
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default PartnersBlock;
