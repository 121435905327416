import React from 'react';
import './FAQBlock.scss';
import Layout from '../../../../components/Layout';
import * as constants from './constants';
import QuestionComponent from './components/QuestionComponent';
import question from './faqInfo';

const FAQBlock = () => (
  <div className="fag__background" id="faq">
    <Layout>
      <div className="fag__container">
        <div className="fag__title">{constants.FAQ}</div>
        <div className="fag__questions-block">
          {question.map((item) => (
            <QuestionComponent
              title={item.question}
              content={item.questionInfo}
              key={item.question}
            />
          ))}
        </div>
      </div>
    </Layout>
  </div>
);

export default FAQBlock;
