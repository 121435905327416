import React from 'react';
import './Footer.scss';
import { Link } from 'react-scroll';
import useDesktopSize from '../../global/helper/useDesktopSize';
import Layout from '../Layout';
import logo from '../../global/images/header/logo2.png';
import * as constants from './constants';
import { links, players, socialLinks } from './footerInfo';

const Footer = () => {
  const isDesktop = useDesktopSize();
  const handleClick = (link) => {
    window.open(link);
  };

  return (
    <div className="footer__background">
      <Layout>
        {isDesktop ? (
          <div className="footer__container">
            <div className="footer__logo">
              <img src={logo} alt="logo" />
            </div>
            <div className="footer__links">
              {links.map((item) => (
                <div
                  className={`footer__link ${
                    item.title === constants.ROADMAP && 'footer__link-roadmap'
                  }`}
                  key={item.id}
                >
                  <Link to={item.id}>{item.title}</Link>
                </div>
              ))}
            </div>
            <div className="footer__players">
              {players.map((item) => (
                <div className="footer__player" key={item.id}>
                  <Link to={item.id}>{item.title}</Link>
                </div>
              ))}
            </div>
            <div className="footer__social-links">
              {socialLinks.map((item) => (
                <div
                  className="footer__social-link"
                  key={item.key}
                  onClick={() => handleClick(item.link)}
                >
                  <img src={item.img} alt={item.key} />
                </div>
              ))}
            </div>
            <div className="footer__end">{constants.COPYRIGHT}</div>
          </div>
        ) : (
          <div className="footer__mobile-container">
            <div className="footer__logo">
              <img src={logo} alt="logo" />
            </div>
            <div className="footer__block-links">
              <div className="footer__links">
                {links.map((item) => (
                  <div
                    className={`footer__link ${
                      item.title === constants.ROADMAP && 'footer__link-roadmap'
                    }`}
                    key={item.id}
                  >
                    <Link to={item.id}>{item.title}</Link>
                  </div>
                ))}
              </div>
              <div className="footer__players">
                {players.map((item) => (
                  <div className="footer__player" key={item.id}>
                    <Link to={item.id}>{item.title}</Link>
                  </div>
                ))}
              </div>
            </div>
            <div className="footer__block-social-links">
              <div className="footer__social-links">
                {socialLinks.map((item) => (
                  <div
                    className="footer__social-link"
                    key={item.key}
                    onClick={() => handleClick(item.link)}
                  >
                    <img src={item.img} alt={item.key} />
                  </div>
                ))}
              </div>
            </div>
            <div className="footer__end">{constants.COPYRIGHT}</div>
          </div>
        )}
      </Layout>
    </div>
  );
};

export default Footer;
