import React from 'react';
import './MainPage.scss';
import TeamBlock from './components/TeamBlock';
import PresentationBlock from './components/PresentationBlock';
import JoinTheBlock from './components/JoinTheBlock';
import Footer from '../../components/Footer';
import FindTribeBlock from './components/FindTribeBlock';
import Vikings9Block from './components/Vikings9Block';
import DividedIntoBlock from './components/DividedIntoBlock';
import UtilityBlock from './components/UtilityBlock';
import RoadmapBlock from './components/RoadmapBlock';
import NtfBlock from './components/NFTBlock';
import ImgAndInfoBlock from './components/ImgAndInfoBlock';
import PartnersBlock from './components/PartnersBlock';
import FAQBlock from './components/FAQBlock/FAQBlock';
import StickyDiscord from '../../components/StickyDiscord';

//eslint-disable-next-line
const MainPage = () => {
  return (
    <div className="background">
      <div className="overflow-hidden">
        <PresentationBlock />
        <FindTribeBlock />
        <Vikings9Block />
        <DividedIntoBlock />
      </div>
      <div className="nft-block">
        <NtfBlock />
      </div>
      <div className="overflow-hidden">
        <UtilityBlock />
        <ImgAndInfoBlock />
        <RoadmapBlock />
      </div>
      <div className="overflow-hidden">
        <JoinTheBlock />
        <PartnersBlock />
        <FAQBlock />
        <TeamBlock />
      </div>
      <div className="sticky-discord">
        <StickyDiscord />
      </div>
      <Footer />
    </div>
  );
};
export default MainPage;
