import React from 'react';
import './ImgAndInfoBlock.scss';
import ImgAndInfo from './components/ImgAndInfo';
import { firstObject, secondObject, thirdObject, fourthObject } from './info';

const ImgAndInfoBlock = () => (
  <div className="img-info__main-container" id="story">
    <ImgAndInfo
      firstImg={firstObject.playImg}
      firstTitle={firstObject.title1}
      firstDesc={firstObject.desc1}
      secondImg={firstObject.img2}
      secondDesc={firstObject.desc2}
      secondTitle={firstObject.title2}
    />
    <ImgAndInfo
      firstImg={secondObject.companions}
      firstTitle={secondObject.title1}
      firstDesc={secondObject.desc1}
      secondImg={secondObject.img4}
      secondDesc={secondObject.desc2}
      secondTitle={secondObject.title2}
    />
    <ImgAndInfo
      firstImg={thirdObject.img1}
      firstTitle={thirdObject.title1}
      firstDesc={thirdObject.desc1}
      secondImg={thirdObject.img6}
      secondDesc={thirdObject.desc2}
      secondTitle={thirdObject.title2}
    />
    <ImgAndInfo
      firstImg={fourthObject.img1}
      firstTitle={fourthObject.title1}
      firstDesc={fourthObject.desc1}
      secondImg={fourthObject.img8}
      secondDesc={fourthObject.desc2}
      secondTitle={fourthObject.title2}
    />
  </div>
);

export default ImgAndInfoBlock;
