import React from 'react';
import './JoinTheBlock.scss';
import Layout from '../../../../components/Layout';
import * as constants from './constants';
import firstbtn from '../../../../global/images/jointhe/discord-btn.svg';
import secondtbtn from '../../../../global/images/jointhe/twitter-btn.svg';
import topsvg from '../../../../global/images/jointhe/Vector 4.svg';
import bottomsvg from '../../../../global/images/jointhe/Vector 5.svg';
import discord from '../../../../global/images/jointhe/Group 34537.svg';
import twitter from '../../../../global/images/jointhe/Frame 1419.svg';
import useDesktopSize from '../../../../global/helper/useDesktopSize';

const JoinTheBlock = () => {
  const isDesktop = useDesktopSize();
  const handleClick = (link) => {
    window.open(link);
  };

  return (
    <div className="join-the__background">
      <Layout>
        <div className="join-the__container">
          <div className="join-the__title">{constants.TITLE}</div>
          <div className="join-the__description">
            <div>{constants.DESCRIPTION}</div>
          </div>
          <div className="join-the__buttons">
            <div className="join-the__button" onClick={() => handleClick(constants.DISCORD_URL)}>
              {isDesktop ? (
                <img src={firstbtn} alt="discord" />
              ) : (
                <div className="join-the__button-mobile">
                  <img src={topsvg} alt="topsvg" className="join-the__topsvg" />
                  <img src={discord} alt="discrod" />
                  <img src={bottomsvg} alt="bottomsvg" className="join-the__bottomsvg" />
                </div>
              )}
            </div>
            <div className="join-the__button" onClick={() => handleClick(constants.TWITTER_URL)}>
              {isDesktop ? (
                <img src={secondtbtn} alt="button" />
              ) : (
                <div className="join-the__button-mobile">
                  <img src={topsvg} alt="topsvg" className="join-the__topsvg" />
                  <img src={twitter} alt="twitter" />
                  <img src={bottomsvg} alt="bottomsvg" className="join-the__bottomsvg" />
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default JoinTheBlock;
