import React from 'react';
import './Vikings9Block.scss';
import * as constants from './constants';
import Video from './components/Video/Video';
import Layout from '../../../../components/Layout/Layout';

const Vikings9Block = () => (
  <Layout>
    <div className="vikings__container">
      <div className="vikings__flex-center">
        <div className="vikings__img" data-aos="fade-right" data-aos-duration="800">
          <Video />
        </div>
        <div className="vikings__info" data-aos="fade-left" data-aos-duration="800">
          <div className="vikings__info-text">
            <div className="vikings__title">{constants.TITLE}</div>
            <div className="vikings__description">{constants.DESCRIPTION}</div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default Vikings9Block;
