import React from 'react';
import './RoadmapBlock.scss';
import * as constants from './contants';
import line from '../../../../global/images/roadmap/line.png';
import mobline from '../../../../global/images/roadmap/Group 34574.png';
import { firstList, secondList } from './infoList';
import useDesktopSize from '../../../../global/helper/useDesktopSize';
import Layout from '../../../../components/Layout/Layout';

const RoadmapBlock = () => {
  const isDesktop = useDesktopSize();

  return (
    //eslint-disable-next-line
    <>
      {isDesktop ? (
        <div className="roadmap__personal-layout" id="roadmap">
          <div className="roadmap__title">{constants.TITLE}</div>
          <div className="roadmap__container">
            <div className="roadmap__first-block">
              {firstList.map((item) => (
                <div className={`roadmap__list-block roadmap__${item.class}`} key={item.title}>
                  <div className="roadmap__list-title">{item.title}</div>
                  {item.items.map((item) => (
                    <ul key={item}>
                      <li className="roadmap__list-item">{item}</li>
                    </ul>
                  ))}
                </div>
              ))}
            </div>
            <div className="roadmap__line">
              <img src={line} alt="line" />
            </div>
            <div className="roadmap__second-block">
              {secondList.map((item) => (
                <div className={`roadmap__list-block roadmap__${item.class}`} key={item.title}>
                  <div className="roadmap__list-title">{item.title}</div>
                  {item.items.map((item) => (
                    <ul key={item}>
                      <li className="roadmap__list-item">{item}</li>
                    </ul>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div id="roadmap">
          <Layout>
            <div className="roadmap__title">{constants.TITLE}</div>
            <div className="roadmap__mobile-container">
              <div className="roadmap__line-mobile">
                <img src={mobline} alt="line" />
              </div>
              <div className="roadmap__container-mobile">
                <div className="roadmap__block">
                  {firstList.map((item) => (
                    <div
                      className={`roadmap__list-block-mobile roadmap__mob-${item.class}`}
                      key={item.title}
                    >
                      <div className="roadmap__list-title">{item.title}</div>
                      {item.items.map((item) => (
                        <ul key={item}>
                          <li className="roadmap__list-item">{item}</li>
                        </ul>
                      ))}
                    </div>
                  ))}
                  {secondList.map((item) => (
                    <div
                      className={`roadmap__list-block-mobile roadmap__mob-${item.class}`}
                      key={item.title}
                    >
                      <div className="roadmap__list-title">{item.title}</div>
                      {item.items.map((item) => (
                        <ul key={item}>
                          <li className="roadmap__list-item">{item}</li>
                        </ul>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Layout>
        </div>
      )}
    </>
  );
};

export default RoadmapBlock;
