import React from 'react';
import '../../DividedIntoBlock.scss';
import firstmarker from '../../../../../../global/images/dividedinto/opened.svg';
import secondmarker from '../../../../../../global/images/dividedinto/closed.svg';

const ListItem = ({ info, desc, onClick, isOpen, id }) => {
  const handleIsOpenItem = () => onClick(id);

  return (
    <div>
      <div className="divided-into__item">
        <div className="divided-into__item-marker">
          {isOpen ? (
            <img src={firstmarker} alt="marker" />
          ) : (
            <img src={secondmarker} alt="marker" />
          )}
        </div>
        <div className="divided-into__item-block">
          <div
            className={`divided-into__item-info ${isOpen && `divided-into__item-info-first`}`}
            onClick={handleIsOpenItem}
          >
            {info}
          </div>
          {isOpen && (
            <div
              className="divided-into__item-desc"
              data-aos="fade-right"
              data-aos-duration="500"
              data-aos-easing="easy-out"
            >
              {desc}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListItem;
