export const PLAY_TO_EARN = 'PLAY TO EARN';
export const GEOS = 'GEOS';
export const COMPANIONS = 'COMPANIONS';
export const ITEMS = 'ITEMS';
export const STAKING = 'STAKING';
export const LAND = 'LAND';
export const EXCLUSIVE_EXPERIENCE = 'EXCLUSIVE EXPERIENCE';
export const MERCH = 'MERCH';

export const DESC1 = 'Using play-to-earn mechanics, Vikings can earn GEO Tokens and NFTs.';
export const DESC2 =
  'The lifeblood of commerce in the Viking world, GEOs are earned token systems to buy, sell, trade throughout the 9 Worlds.';
export const DESC3 =
  "Extraordinary creatures like dragons, wolves, battle-cats can be tamed and used to fight alongside in battles. You can use your companion's ability to affect the tide of the fight, making victory that much more glorious!";
export const DESC4 =
  'Booster items, leveling items, status items, etc., can be bought with your GEO coins to move and battle across the 9 Worlds.';
export const DESC5 =
  'You will be able to stake your Viking NFTs, earn GEOs, and get bonus NFTs like battle animals and weapons.';
export const DESC6 =
  'Land can be held, bought, crafted on, and sold not only in Midgard(Earth), but you can become a landlord in all the nine realms.';
export const DESC7 =
  "As an NFT holder, you, the community, will decide the game's direction, get Whitelist spots, and get insider information on the upcoming sales and newer developments.";
export const DESC8 =
  'The Odin 9 Worlds merchandise store will unlock multiple drops throughout the year. In addition, 25% of all sales proceeds will be added to the O9W community wallet, and this wallet will be dedicated to funding Odin 9 Worlds community functions';
