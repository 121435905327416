import * as constants from './constants';
import manuel from '../../../../global/images/team/manuel-min.png';
import singh from '../../../../global/images/team/Anant-min.png';
import pieniek from '../../../../global/images/team/Ania (2)-min.png';
import petras from '../../../../global/images/team/Ian (2)-min.png';
import gomez from '../../../../global/images/team/alvaro (2)-min.png';
import carazd from '../../../../global/images/team/Óscar-min.png';
import demera from '../../../../global/images/team/Ronny (2)-min.png';
import portillo from '../../../../global/images/team/Toni-min.png';
import casa from '../../../../global/images/team/Alejandro-min.png';
import asis from '../../../../global/images/team/Tarek (2)-min.png';

/* eslint-disable-next-line */
export const teamInfo = [
  {
    img: manuel,
    name: constants.MANUEL,
    position: constants.POSITION_MANUEL,
  },
  {
    img: singh,
    name: constants.SINGH,
    position: constants.POSITION_SINGH,
  },
  {
    img: pieniek,
    name: constants.PIENIEK,
    position: constants.POSITION_PIENIEK,
  },
  {
    img: petras,
    name: constants.PETRAS,
    position: constants.POSITION_PETRAS,
  },
  {
    img: gomez,
    name: constants.GOMEZ,
    position: constants.POSITION_GOMEZ,
  },
  {
    img: carazd,
    name: constants.CARAZO,
    position: constants.POSITION_CARAZO,
  },
  {
    img: demera,
    name: constants.DEMERA,
    position: constants.POSITION_DEMERA,
  },
  {
    img: portillo,
    name: constants.PORTILLO,
    position: constants.POSITION_PORTILLO,
  },
  {
    img: casa,
    name: constants.CASA,
    position: constants.POSITION_CASA,
  },
  {
    img: asis,
    name: constants.ASIS,
    position: constants.POSITION_ASIS,
  },
];
