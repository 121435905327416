import * as constants from './contants';

export const firstList = [
  {
    title: constants.TITLE1,
    items: [
      constants.ORIGIN_INFO1,
      constants.ORIGIN_INFO2,
      constants.ORIGIN_INFO3,
      constants.ORIGIN_INFO4,
      constants.ORIGIN_INFO5,
      constants.ORIGIN_INFO6,
      constants.ORIGIN_INFO7,
    ],
    class: 'firstInFirst',
  },
  {
    title: constants.TITLE3,
    items: [
      constants.HOARD_INFO1,
      constants.HOARD_INFO2,
      constants.HOARD_INFO3,
      constants.HOARD_INFO4,
    ],
    class: 'secondInFirst',
  },
];

export const secondList = [
  {
    title: constants.TITLE2,
    items: [
      constants.MIDGARD_INFO1,
      constants.MIDGARD_INFO2,
      constants.MIDGARD_INFO3,
      constants.MIDGARD_INFO4,
      constants.MIDGARD_INFO5,
    ],
    class: 'firstInSecond',
  },
  {
    title: constants.TITLE4,
    items: [
      constants.BATTLE_INFO1,
      constants.BATTLE_INFO2,
      constants.BATTLE_INFO3,
      constants.BATTLE_INFO4,
    ],
    class: 'secondInSecond',
  },
];
