import React from 'react';
import './TeamBlock.scss';
import Layout from '../../../../components/Layout';
import * as constants from './constants';
import { teamInfo } from './teamInfo';

const TeamBlock = () => (
  <div className="team__background" id="team">
    <Layout>
      <div className="team__container">
        <div className="team__title">{constants.TITLE}</div>
        <div className="team__grid">
          {teamInfo.map((item) => (
            <div className="team__info" key={item.name}>
              <div className="team__info-img">
                <img src={item.img} alt={item.name} />
              </div>
              <div className="team__name">{item.name}</div>
              <div className="team__position">{item.position}</div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  </div>
);

export default TeamBlock;
