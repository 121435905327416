import React from 'react';
import './FindTribeBlock.scss';
import useDesktopSize from '../../../../global/helper/useDesktopSize';
import Layout from '../../../../components/Layout';
import * as constants from './constants';
import btn from '../../../../global/images/findtribe/btn.svg';
import topsvg from '../../../../global/images/jointhe/Vector 4.svg';
import bottomsvg from '../../../../global/images/jointhe/Vector 5.svg';
import discord from '../../../../global/images/findtribe/Frame 1422.svg';

const FindTribeBlock = () => {
  const handleClick = (link) => {
    window.open(link);
  };

  const isDesktop = useDesktopSize();

  return (
    <div
      className={`find-tribe__main-container ${!isDesktop && 'find-tribe__main-container-mobile'}`}
    >
      <Layout>
        <div className="find-tribe__container">
          <div className="find-tribe__desc">
            <div
              className={`find-tribe__description ${
                !isDesktop && 'find-tribe__description-mobile'
              }`}
            >
              {constants.DESCRIPTION1}
            </div>
            <div
              className={`find-tribe__description ${
                !isDesktop && 'find-tribe__description-mobile'
              }`}
            >
              {constants.DESCRIPTION2}
            </div>
            <div
              className={`find-tribe__description ${
                !isDesktop && 'find-tribe__description-mobile'
              }`}
            >
              {constants.DESCRIPTION3}
            </div>
          </div>
          <div
            className={`find-tribe__button ${!isDesktop && 'find-tribe__button-mobile'}`}
            onClick={() => handleClick(constants.BTN_URL)}
          >
            {isDesktop ? (
              <img src={btn} alt="button" />
            ) : (
              <div className="find-tribe__button-mobile">
                <img src={topsvg} alt="topsvg" className="find-tribe__topsvg" />
                <img src={discord} alt="discord" />
                <img src={bottomsvg} alt="bottomsvg" className="find-tribe__bottomsvg" />
              </div>
            )}
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default FindTribeBlock;
