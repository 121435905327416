import React, { useEffect, useState } from 'react';
import './Header.scss';
import { Link } from 'react-scroll';
import Layout from '../Layout';
import { links, players, socialLinks } from './headerInfo';
import * as constants from './constants';
import burger from '../../global/images/header/icon.svg';
import MobileMenu from './components/MobileMenu/MobileMenu';

const Header = () => {
  const [isOpenMobileMenu, setIsOpenMobile] = useState(false);
  const [isDesktopForHeader, setIsDesktopForHeader] = useState(null);

  useEffect(() => {
    setIsDesktopForHeader(window.outerWidth >= 1024);
  }, [isDesktopForHeader]);

  const handleClick = (link) => {
    window.open(link);
  };

  return (
    <>
      {isDesktopForHeader ? (
        <Layout>
          <div className="header__container">
            <div className="header__links">
              {links.map((item) => (
                <div className="header__link" key={item.title}>
                  <Link to={item.id} smooth>
                    {item.title}
                  </Link>
                </div>
              ))}
            </div>
            <div className="header__logo">
              <div className="header__logo-title">{constants.LOGO_TITLE}</div>
            </div>
            <div className="header__players">
              {players.map((item) => (
                <div className="header__player" key={item.title}>
                  <Link to={item.id} smooth>
                    {item.title}
                  </Link>
                </div>
              ))}
            </div>
            <div className="header__social-links">
              {socialLinks.map((item) => (
                <div
                  className="header__social-link"
                  key={item.key}
                  onClick={() => handleClick(item.link)}
                >
                  <img src={item.img} alt={item.key} />
                </div>
              ))}
            </div>
          </div>
        </Layout>
      ) : (
        //eslint-disable-next-line
        <>
          {!isOpenMobileMenu && (
            <div className="header__container-mobile">
              <div className="header__logo-mobile">
                <div className="header__logo-title-mobile">{constants.LOGO_TITLE}</div>
              </div>
              <div className="header__burger" onClick={() => setIsOpenMobile(!isOpenMobileMenu)}>
                <img src={burger} alt="burder" />
              </div>
            </div>
          )}
        </>
      )}
      {isOpenMobileMenu && <MobileMenu onClick={() => setIsOpenMobile(!isOpenMobileMenu)} />}
    </>
  );
};

export default Header;
