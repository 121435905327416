export const DESCRIPTION =
  'Out of 9,999 NFTs, only NINE individually drawn NFTs of Viking rulers and shield-maidens possess the rarest characteristics to be classified LEGENDARY! These 9 Viking Legends are unlike any other NFT in the 9 Worlds. If you own them, you not only own rare NFTs, you own a part of the Legend.';

export const TITLE = 'They are divided into 4 categories:';
export const LORDS = '9 Legendary Viking Lords';
export const EPIC = 'Epic 90';
export const FAB = 'Fab 900';
export const COMMON = 'Common 9000';
export const INFO =
  'This one-of-a-kind Norse universe has been designed for your inner Viking, with innovative utilities, community building mechanisms, and epic battles across 9 Worlds.';

export const LORDS_DESC =
  'Out of 9,999 NFTs, only NINE individually drawn NFTs of Viking rulers and shield-maidens possess the rarest characteristics to be classified LEGENDARY! These 9 Viking Legends are unlike any other NFT in the 9 Worlds. If you own them, you not only own rare NFTs, you own a part of the Legend.';
export const EPIC_DESC =
  "Unique in their way, these 90 Wisefolks (healers), Seiðr (shamans and seers), and traders are the most sought after NFTs post-Ragnarok. They might not be 'legendary', but they are the EPIC and your only way out in the New World.";
export const FAB_DESC =
  'In this brave new world, these 900 Hunters (trackers), blacksmiths (weapon makers), jewelry makers, and boat builders will be able to forge and sculpt a future out of nothing. If you own any of the 10% of the 9,999 NFTs, your gameplay will be simply FAB.';
export const COMMON_DESC =
  'Legend has it that in the post-apocalyptic world, the ultimate seafaring warriors, raiders and pillagers will pave the way for the new reality. Even when you own any of these Common 9000 NFTs, you own something unique.';
