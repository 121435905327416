import React, { useState, useEffect } from 'react';
import './NFTBlock.scss';

import useDesktopSize from '../../../../global/helper/useDesktopSize';
import Layout from '../../../../components/Layout';
import * as constants from './constants';
import SwiperPhoto from './components/SwiperPhoto';

const NFTBlock = () => {
  const isDesktop = useDesktopSize();
  const [scroll, setScroll] = useState(0);
  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Layout>
      {isDesktop ? (
        <div className="ntf__centered">
          <div className="ntf__grid">
            <div className="ntf__first-block">
              <div className="ntf__images">{scroll > 1500 && <SwiperPhoto />}</div>
            </div>
            <div className="ntf__info">
              <div className="ntf__title">{constants.TITLE}</div>
              <div className="ntf__desc">{constants.INFO}</div>
            </div>
          </div>
          <div className="ntf__swipe">
            <div>{constants.SWIPE}</div>
          </div>
        </div>
      ) : (
        <div className="ntf__centered-mobile">
          <div className="ntf__info">
            <div className="ntf__title">{constants.TITLE}</div>
            <div className="ntf__desc">{constants.INFO}</div>
          </div>
          <div className="ntf__images-mobile">{scroll > 1500 && <SwiperPhoto />}</div>
          <div className="ntf__swipe-mobile">
            <div>{constants.SWIPE}</div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default NFTBlock;
