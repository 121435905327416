import { useState } from 'react';

const useListItem = (listItemData) => {
  const [listItemState, setListItemState] = useState(
    listItemData?.reduce(
      (state, element) => ({
        ...state,
        [element.id]: false,
      }),
      {},
    ) || {},
  );

  const handleChangListItemState = (id) => {
    setListItemState(
      (prev) =>
        listItemData?.reduce(
          (state, element) => ({
            ...state,
            [element.id]: id === element.id ? !prev[id] : false,
          }),
          {},
        ) || {},
    );
  };

  return [listItemState, handleChangListItemState];
};

export default useListItem;
