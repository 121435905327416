import React, { useState } from 'react';
import './PresentationBtn.scss';
import firstbtn from '../../../../../../global/images/presentation/prepare-btn.svg';
import secondtbtn from '../../../../../../global/images/presentation/enter-btn.svg';
import enterSoon from '../../../../../../global/images/presentation/enter-soon.svg';
import firstmobile from '../../../../../../global/images/presentation/PREPARE TO BATTLE.svg';
import soonmob from '../../../../../../global/images/presentation/AVAILABLE SOON.svg';
import secondmobile from '../../../../../../global/images/presentation/ENTER THE 9 WORLDS.svg';
import useDesktopSize from '../../../../../../global/helper/useDesktopSize';
import topsvg from '../../../../../../global/images/jointhe/Vector 4.svg';
import bottomsvg from '../../../../../../global/images/jointhe/Vector 5.svg';
import * as constants from '../../constants';

const PresentationBtn = () => {
  const isDesktop = useDesktopSize();
  const [isNoHoverSecond, setHoverSecond] = useState(true);
  const hoverSecond = () => setHoverSecond((prev) => !prev);

  const handleClick = (url) => {
    window.open(url);
  };
  return (
    <>
      {isDesktop && (
        <>
          <div className="presentation__button" onClick={() => handleClick(constants.BTN1_URL)}>
            <img src={firstbtn} alt="button" />
          </div>
          <div
            className="presentation__button"
            onMouseEnter={hoverSecond}
            onMouseLeave={hoverSecond}
          >
            {isNoHoverSecond ? (
              <img src={secondtbtn} alt="button" />
            ) : (
              <img src={enterSoon} alt="button" />
            )}
          </div>
          )}
        </>
      )}
      {!isDesktop && (
        <>
          <div className="presentation__button" onClick={() => handleClick(constants.BTN1_URL)}>
            <div className="presentation-btn__button-mobile">
              <img src={topsvg} alt="topsvg" className="find-tribe__topsvg" />
              <img src={firstmobile} alt="discord" />
              <img src={bottomsvg} alt="bottomsvg" className="find-tribe__bottomsvg" />
            </div>
          </div>
          <div
            className="presentation__button"
            onMouseEnter={hoverSecond}
            onMouseLeave={hoverSecond}
          >
            {isNoHoverSecond ? (
              <div className="presentation-btn__button-mobile">
                <img src={topsvg} alt="topsvg" className="find-tribe__topsvg" />
                <img src={secondmobile} alt="discord" />
                <img src={bottomsvg} alt="bottomsvg" className="find-tribe__bottomsvg" />
              </div>
            ) : (
              <div className="presentation-btn__button-mobile">
                <img src={topsvg} alt="topsvg" className="find-tribe__topsvg" />
                <img src={soonmob} alt="discord" />
                <img src={bottomsvg} alt="bottomsvg" className="find-tribe__bottomsvg" />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default PresentationBtn;
