import * as constants from './constants';
import twitterLogo from '../../global/images/header/twitter.svg';
import discordLogo from '../../global/images/header/discord.svg';
import instagramLogo from '../../global/images/header/instagram.svg';

export const links = [
  {
    title: constants.STORY,
    id: 'story',
  },
  {
    title: constants.ROADMAP,
    id: 'roadmap',
  },
  {
    title: constants.TEAM,
    id: 'team',
  },
  {
    title: constants.FAQ,
    id: 'faq',
  },
];
export const players = [
  {
    title: constants.SINGLE_PLAYER,
    id: 'player1',
  },
  {
    title: constants.MULTIPLAYER,
    id: 'player2',
  },
];
export const socialLinks = [
  {
    img: twitterLogo,
    key: 'twitter',
    link: 'https://twitter.com/odin9worlds',
  },
  {
    img: discordLogo,
    key: 'discord',
    link: 'https://discord.com/invite/o9w',
  },
  {
    img: instagramLogo,
    key: 'instagram',
    link: 'https://www.instagram.com/odin9worlds/',
  },
];
