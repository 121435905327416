export const GELAGO = 'Luis gelado';
export const POSITION_GELAGO = 'Co-Founder';

export const MANUEL = 'manuel DE la esperanza';
export const POSITION_MANUEL = 'Co-Founder';

export const CHEVTAEV = 'Alex Chevtaev';
export const POSITION_CHEVTAEV = 'Co-Founder';

export const SINGH = 'Anant Singh';
export const POSITION_SINGH = 'Co-Founder';

export const PIENIEK = 'Ania Pieniek';
export const POSITION_PIENIEK = 'Art Director';

export const CARMONA = 'Alberto Carmona';
export const POSITION_CARMONA = 'CTO';

export const PETRAS = 'Ian petras';
export const POSITION_PETRAS = 'Marketing head';

export const GOMEZ = 'alvaro gomez';
export const POSITION_GOMEZ = 'Blockchain Dev';

export const CARAZO = 'Óscar Carazo';
export const POSITION_CARAZO = 'Frontend Dev';

export const DEMERA = 'Ronny Demera';
export const POSITION_DEMERA = 'Blockchain Dev';

export const PORTILLO = 'Toni Portillo';
export const POSITION_PORTILLO = 'Backend Dev';

export const CASA = 'Alejandro Casa Rodriguez';
export const POSITION_CASA = 'Backend Dev';

export const ASIS = 'Tarek Asis';
export const POSITION_ASIS = 'Backend Dev';

export const TITLE = 'TEAM';
