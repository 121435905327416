import { useLayoutEffect } from 'react';

export default function useLockBodyScroll() {
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = 'hidden';
    //eslint-disable-next-line
    return () => (document.body.style.overflow = originalStyle);
  }, []);
}
