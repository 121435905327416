import * as constants from './constants';

const question = [
  {
    question: constants.QUESTION1,
    questionInfo: constants.QUESTION1_INFO,
  },
  {
    question: constants.QUESTION2,
    questionInfo: constants.QUESTION2_INFO,
  },
  {
    question: constants.QUESTION3,
    questionInfo: constants.QUESTION3_INFO,
  },
  {
    question: constants.QUESTION4,
    questionInfo: constants.QUESTION4_INFO,
  },
  {
    question: constants.QUESTION5,
    questionInfo: constants.QUESTION5_INFO,
  },
  {
    question: constants.QUESTION6,
    questionInfo: constants.QUESTION6_INFO,
  },
  {
    question: constants.QUESTION7,
    questionInfo: constants.QUESTION7_INFO,
  },
  {
    question: constants.QUESTION8,
    questionInfo: constants.QUESTION8_INFO,
  },
];

export default question;
