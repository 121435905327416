import React from 'react';
import './DividedIntoBlock.scss';
import * as constants from './constants';
import firstImg from '../../../../global/images/dividedinto/firstImg.png';
import secondImg from '../../../../global/images/dividedinto/secondImg.png';
import ListItem from './components/ListItem';
import { list } from './infoDivided';
import useListItem from '../../../../global/helper/useListItem';
import Layout from '../../../../components/Layout/Layout';

const DividedIntoBlock = () => {
  const [listItemState, setListItemState] = useListItem(list);
  const handleChangeListItem = (id) => setListItemState(id);

  return (
    <Layout>
      <div className="divided-into__personal-layout">
        <div className="divided-into__container">
          <div className="divided-into__first-block">
            <div className="divided-into__title">{constants.TITLE}</div>
            <div className="divided-into__list">
              {list.map((i) => (
                <div key={i.id}>
                  <ListItem
                    id={i.id}
                    desc={i.desc}
                    info={i.info}
                    isOpen={listItemState[i.id]}
                    onClick={handleChangeListItem}
                  />
                </div>
              ))}
            </div>
            <div className="divided-into__info">{constants.INFO}</div>
          </div>
          {}
          <div className="divided-into__second-block">
            <div
              className="divided-into__first-img"
              data-aos="zoom-in"
              data-aos-duration="900"
              data-aos-delay="400"
            >
              <img src={firstImg} alt="photo" />
            </div>
            <div className="divided-into__second-img" data-aos="zoom-in" data-aos-duration="900">
              <img src={secondImg} alt="photo" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DividedIntoBlock;
