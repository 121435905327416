export const TITLE = 'UTILITY';
export const SUBTITLE = 'Skol Vikings! Are you ready to rule the 9 Worlds?';
export const DESC =
  "From creatures to tame and fight alongside in battle to special booster items that'll help up your status; from a wide variety of weapons to land that can be held, bought, crafted on, and sold, there's a lot in the 9 Worlds to battle and win!";

export const SINGLE_GAME = 'Single Player Game';
export const MULTI_GAME = 'Multi-Player Game';
export const SINGLE_INFO =
  'In a single-player game, you will find weapons in Midgard in the first of a kind NFT sale. You can choose to hold or trade these on the marketplace. As a single-player, you will also race to the nearest pyre to stake your geo tokens to either earn reaping or burn them to awaken the hidden NFT.';
export const MULTI_INFO =
  " In the 9 Worlds, you'll be able to visit towns and other settlements, train for battles with other players, challenge them, brawl, and best of all? Show off your exciting NFT Weapons!";

export const GENERAL =
  'Keep fighting, leveling, crafting, building, trading, and traveling between the 9 Worlds.';
