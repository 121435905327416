import React, { useState, useRef, useLayoutEffect } from 'react';
import './PresentationBlock.scss';
import * as constants from './constants';
import Header from '../../../../components/Header';
import Layout from '../../../../components/Layout';
import bgMob from '../../../../global/images/presentation/Group 774.png';
import animatedBg from '../../../../global/images/presentation/Frame 34570.png';
import useDesktopSize from '../../../../global/helper/useDesktopSize';
import PresentationBtn from './components/PresentationBtn';
import CountdownWithButton from './components/CountdownWithButton';

const PresentationBlock = () => {
  const isDesktop = useDesktopSize();

  const preScroll = useRef(null);
  const elemRef = useRef(null);
  const [scaleMob, setScaleMob] = useState(0.7);
  const [scaleDesc, setScaleDesc] = useState(0.9);

  useLayoutEffect(() => {
    const botPos = (element) => element.getBoundingClientRect().bottom;
    const onScroll = () => {
      const divBotPos = botPos(elemRef.current);
      const scrollPos = preScroll.current > window.scrollY;
      preScroll.current = window.scrollY;
      if (scrollPos == null) {
        return;
      }
      if (!isDesktop) {
        if (scrollPos && divBotPos + 1800 > window.innerHeight) {
          setScaleMob(0.7);
          return;
        }
      } else {
        /* eslint-disable-next-line */
        if (scrollPos && divBotPos > window.innerHeight) {
          setScaleDesc(0.9);
          return;
        }
      }

      if (divBotPos - 400 < window.innerHeight) {
        if (isDesktop) {
          setScaleDesc(1);
        } else setScaleMob(0.8);
      }
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [isDesktop]);

  return (
    <div className={`${isDesktop && 'presentation__background'}`}>
      <div className="header">
        <Header />
      </div>
      {!isDesktop && <img src={bgMob} alt="bg" className="bg-mob" />}
      {isDesktop && (
        <div className="presentation__counter">
          <CountdownWithButton />
        </div>
      )}
      <div className="presentation__animated-block">
        <img
          className="image"
          src={animatedBg}
          alt="animatedBg"
          ref={elemRef}
          style={{
            transition: 'transform 1.2s ease-in-out',
            transform: `scale(${isDesktop ? scaleDesc : scaleMob})`,
          }}
        />
      </div>
      <Layout>
        <div className="presentation__container">
          <div className="presentation__title"> {constants.TITLE} </div>
          <div className="presentation__buttons">
            <PresentationBtn />
          </div>
        </div>
        {!isDesktop && (
          <div className="presentation__counter-mob">
            <CountdownWithButton />
          </div>
        )}
      </Layout>
    </div>
  );
};

export default PresentationBlock;
