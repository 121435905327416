export const FAQ = 'FAQ';

export const QUESTION1 = 'ODIN 9 WORLDS (O9W) NFT MINTING';
export const QUESTION2 = 'IS THERE A WHITELIST?';
export const QUESTION3 = 'WHAT IS ODIN 9 WORLDS?';
export const QUESTION4 = 'HOW CAN I USE MY NFT ?';
export const QUESTION5 = 'WHAT IS METAMASK?';
export const QUESTION6 = 'WHAT BLOCKCHAIN IS IT ON?';
export const QUESTION7 = 'WHERE CAN I SEE MY OG?';
export const QUESTION8 = 'HOW DO I CONTACT THE TEAM?';

export const QUESTION1_INFO =
  'Minting will be exclusively on our website. We will begin with minting weapons, followed by minting of Viking NFTs from Midgard and as the worlds get revealed the mint will progress to other eight worlds. We will share the exact date and time on Discord';
export const QUESTION2_INFO =
  'Yes, our whitelist system is divided in 2 exclusive clubs, OG WL & WL. Spots are earned by active members of our Discord.';
export const QUESTION3_INFO =
  'It’s a unique NFT collection of 9999 unique generative art pieces with utilities for the 9 worlds Ecosystem. There are more than 250 unique hand-drawn attributes.';
export const QUESTION4_INFO =
  'Beyond the Odin 9 World’s collectibles, your NFTs will be useable as an avatar in our social RPG, allowing you to interact and trade with other people, play the game, conquer lands, trade & manage them. 9 Worlds NFTs give access to this whole universe.';
export const QUESTION5_INFO = 'Metamask is a the crypto wallet used to mint O9W NFT.';
export const QUESTION6_INFO =
  'The first mints will be on the Polygon Blockchain and file hosting is IPFS.';
export const QUESTION7_INFO =
  'Once you successfully mint O9W NFT, you can view it by connecting your wallet to OpenSea and use it at that time.';
export const QUESTION8_INFO =
  'You can either join our discord and ask any questions you have. All essential information will be announced on Discord.';
