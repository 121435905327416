import { useEffect, useState } from 'react';

const useDesktopSize = () => {
  const [isDesktop, setIsDesktop] = useState(null);
  /* eslint-disable-next-line */
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setIsDesktop(window.outerWidth >= 768);
      };

      window.addEventListener('resize', handleResize);
      handleResize();

      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);
  return isDesktop;
};

export default useDesktopSize;
