import React, { useState } from 'react';
import { useSprings, animated, to as interpolate } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import './SwiperPhoto.scss';
import img5 from '../../../../../../global/images/swiper/62-min.png';
import img1 from '../../../../../../global/images/swiper/128-min.png';
import img4 from '../../../../../../global/images/swiper/192-min.png';
import img2 from '../../../../../../global/images/swiper/202-min.png';
import img3 from '../../../../../../global/images/swiper/357-min.png';
import img6 from '../../../../../../global/images/swiper/529-min.png';
import img7 from '../../../../../../global/images/swiper/694-min.png';

const cards = [img1, img2, img3, img5, img6, img7, img4];

const to = (i) => ({
  x: 0,
  y: i * -4,
  scale: 1,
  rot: -10 + Math.random() * 20,
  delay: i * 100,
});
const from = () => ({ x: -2000, rot: 0, scale: 1.5, y: 0 });
const trans = (r, s) =>
  `perspective(1500px) rotateX(30deg) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`;

const Deck = () => {
  const [gone] = useState(() => new Set());
  const [props, api] = useSprings(cards.length, (i) => ({
    ...to(i),
    from: from(i),
  }));

  const bind = useDrag(
    ({ args: [index], active, movement: [mx], direction: [xDir], velocity: [vx] }) => {
      const trigger = vx > 0.2;
      if (!active && trigger) gone.add(index);
      api.start((i) => {
        if (index !== i) return;
        const isGone = gone.has(index);
        /* eslint-disable-next-line */
        const x = isGone ? (200 + window.innerWidth) * xDir : active ? mx : 0;
        const rot = mx / 100 + (isGone ? xDir * 10 * vx : 0);
        /* eslint-disable-next-line */
        const scale = active ? 1.1 : 1;
        /* eslint-disable-next-line */
        return {
          x,
          rot,
          scale,
          delay: undefined,
          /* eslint-disable-next-line */
          config: { friction: 50, tension: active ? 800 : isGone ? 200 : 500 },
        };
      });
      if (!active && gone.size === cards.length)
        setTimeout(() => {
          gone.clear();
          api.start((i) => to(i));
        }, 600);
    },
  );

  return (
    /* eslint-disable-next-line */
    <div className="container">
      {props.map(({ x, y, rot, scale }, i) => (
        /* eslint-disable-next-line */
        <animated.div key={i} style={{ x, y }}>
          {/* eslint-disable-next-line */}
          <animated.div
            className="deck"
            /* eslint-disable-next-line */
            {...bind(i)}
            style={{
              transform: interpolate([rot, scale], trans),
              backgroundImage: `url(${cards[i]})`,
            }}
          />
        </animated.div>
      ))}
    </div>
  );
};

export default Deck;
