import React from 'react';
import './Layout.scss';
import useDesktopSize from '../../global/helper/useDesktopSize';

const Layout = ({ children }) => {
  const isDesktop = useDesktopSize();

  return <div className={`${isDesktop ? 'layout-desktop' : 'layout-mobile'}`}>{children}</div>;
};

export default Layout;
