import React from 'react';
import './MobileMenu.scss';
import { Link } from 'react-scroll';
import { links, players, socialLinks } from '../../headerInfo';
import * as constants from '../../constants';
import closeIcon from '../../../../global/images/header/x.svg';
import useNoScroll from '../../../../global/helper/useNoScroll';

const MobileMenu = ({ onClick }) => {
  const handleClick = (link) => {
    window.open(link);
    onClick();
  };
  useNoScroll();

  return (
    <div className="mobile-menu__container">
      <div className="header__container-mobile-open">
        <div className="header__logo-mobile">
          <div className="header__logo-title-mobile">{constants.LOGO_TITLE}</div>
        </div>
        <div className="header__burger" onClick={onClick}>
          <img src={closeIcon} alt="burder" />
        </div>
      </div>
      <div className="mobile-menu__content">
        <div className="mobile-menu__links">
          {links.map((item) => (
            <div className="mobile-menu__link" key={item.title}>
              <Link to={item.id} smooth onClick={onClick}>
                {item.title}
              </Link>
            </div>
          ))}
        </div>
        <div className="mobile-menu__players">
          {players.map((item) => (
            <div className="mobile-menu__player" key={item.title}>
              <Link to={item.id} smooth onClick={onClick}>
                {item.title}
              </Link>
            </div>
          ))}
        </div>
        <div className="mobile-menu__social-links">
          {socialLinks.map((item) => (
            <div
              className="mobile-menu__social-link"
              key={item.key}
              onClick={() => handleClick(item.link)}
            >
              <img src={item.img} alt={item.key} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
