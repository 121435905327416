import React from 'react';
import odinVideo from '../../video/Odin9Worlds_1.mp4';

//eslint-disable-next-line
const Video = () => {
  return (
    <div>
      <video controls width="100%" height="auto" autoPlay="autoplay" muted>
        <track />
        <source src={odinVideo} type="video/mp4" />
      </video>
    </div>
  );
};

export default Video;
