import React from 'react';
import './StickyDiscord.scss';
import discord from '../../global/images/o9w discord-min.png';
import * as constants from './constants';

const StickyDiscord = () => {
  const handleClick = (link) => {
    window.open(link);
  };

  return (
    <div className="sticky-discord" onClick={() => handleClick(constants.DISCORD_URL)}>
      <img src={discord} alt="discord button" />
    </div>
  );
};

export default StickyDiscord;
