import * as constants from './constants';
import img1 from '../../../../global/images/img/Rectangle 97.png';
import img2 from '../../../../global/images/img/Rectangle 97 (1).png';
import img4 from '../../../../global/images/img/Rectangle 97 (2).png';
import img6 from '../../../../global/images/img/Rectangle 97 (3).png';
import img8 from '../../../../global/images/img/Hoodie 09w.png';
import playImg from '../../../../global/images/img/ragnarok-op3.png';
import companions from '../../../../global/images/img/companions2.png';

/*eslint-env es6*/
export const firstObject = {
  playImg,
  title1: constants.PLAY_TO_EARN,
  desc1: constants.DESC1,
  img2,
  title2: constants.GEOS,
  desc2: constants.DESC2,
};

export const secondObject = {
  companions,
  title1: constants.COMPANIONS,
  desc1: constants.DESC3,
  img4,
  title2: constants.ITEMS,
  desc2: constants.DESC4,
};

export const thirdObject = {
  img1,
  title1: constants.STAKING,
  desc1: constants.DESC5,
  img6,
  title2: constants.LAND,
  desc2: constants.DESC6,
};

export const fourthObject = {
  img1,
  title1: constants.EXCLUSIVE_EXPERIENCE,
  desc1: constants.DESC7,
  img8,
  title2: constants.MERCH,
  desc2: constants.DESC8,
};
