import React from 'react';
import './UtilityBlock.scss';

import useDesktopSize from '../../../../global/helper/useDesktopSize';
import * as constants from './constants';
import Layout from '../../../../components/Layout';
import mainImg from '../../../../global/images/utility/main-img.png';
import mobileImg from '../../../../global/images/utility/Rectangle 95 (4)-min.png';

const UtilityBlock = () => {
  const isDesktop = useDesktopSize();

  return (
    <div id="player1">
      <Layout>
        <div className="utility__centered" id="player2">
          <div className="utility__grid">
            <div className="utility__first">
              <div className="utility__title">{constants.TITLE}</div>
              <div className="utility__subtitle">{constants.SUBTITLE}</div>
            </div>
            <div className="utility__second">
              <div className="utility__desc">{constants.DESC}</div>
            </div>
          </div>

          <div className="utility__img">
            <img src={isDesktop ? mainImg : mobileImg} alt="mainImg" />
          </div>
          <div className="utility__grid-second">
            <div className="utility__first">
              <div className="utility__game-title">{constants.SINGLE_GAME}</div>
              <div className="utility__game-info">{constants.SINGLE_INFO}</div>
            </div>
            <div className="utility__second">
              <div className="utility__game-title">{constants.MULTI_GAME}</div>
              <div className="utility__game-info">{constants.MULTI_INFO}</div>
            </div>
          </div>
          <div className="utility__additional">
            <div>{constants.GENERAL}</div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default UtilityBlock;
