import * as constants from './constants';

/* eslint-disable-next-line */
export const list = [
  {
    info: constants.LORDS,
    id: constants.LORDS,
    desc: constants.LORDS_DESC,
  },
  {
    info: constants.EPIC,
    id: constants.EPIC,
    desc: constants.EPIC_DESC,
  },
  {
    info: constants.FAB,
    id: constants.FAB,
    desc: constants.FAB_DESC,
  },
  {
    info: constants.COMMON,
    id: constants.COMMON,
    desc: constants.COMMON_DESC,
  },
];
