import React from 'react';
import './global/styles/index.scss';
import AnimatedCursor from 'react-animated-cursor';
import MainPage from './pages/MainPage/MainPage';

const App = () => (
  <div className="App">
    <AnimatedCursor
      innerSize={10}
      outerSize={10}
      color="255, 255 ,255"
      outerAlpha={0.4}
      innerScale={0.7}
      outerScale={5}
      trailingSpeed={10}
    />
    <MainPage />
  </div>
);

export default App;
