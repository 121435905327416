import React, { useEffect, useState } from 'react';
import './ImgAndInfo.scss';
import Layout from '../../../../../../components/Layout';

const ImgAndInfo = ({ firstImg, firstTitle, firstDesc, secondImg, secondTitle, secondDesc }) => {
  const [isDesktopForIngAndInfo, setIsDesktopForIngAndInfo] = useState(null);

  useEffect(() => {
    setIsDesktopForIngAndInfo(window.outerWidth >= 910);
  }, [isDesktopForIngAndInfo]);

  const infoForMobileVersion = [
    {
      img: firstImg,
      title: firstTitle,
      desc: firstDesc,
    },
    {
      img: secondImg,
      title: secondTitle,
      desc: secondDesc,
    },
  ];

  return (
    <div className="general-container">
      {isDesktopForIngAndInfo ? (
        <>
          <div className="img-info__container">
            <Layout>
              <div className="img-info__flex-center">
                <div className="img-info__img" data-aos="fade-right" data-aos-duration="800">
                  <img src={firstImg} alt="photo" />
                </div>
                <div className="img-info__info" data-aos="fade-left" data-aos-duration="900">
                  <div className="img-info__info-text">
                    <div className="img-info__title">{firstTitle}</div>
                    <div className="img-info__description">{firstDesc}</div>
                  </div>
                </div>
              </div>
            </Layout>
          </div>
          <div className="img-info__container2">
            <Layout>
              <div className="img-info__flex-center2">
                <div className="img-info__img2" data-aos="fade-left" data-aos-duration="800">
                  <img src={secondImg} alt="photo" />
                </div>
                <div className="img-info__info2">
                  <div
                    className="img-info__info-text2"
                    data-aos="fade-right"
                    data-aos-duration="900"
                  >
                    <div className="img-info__title2">{secondTitle}</div>
                    <div className="img-info__description2">{secondDesc}</div>
                  </div>
                </div>
              </div>
            </Layout>
          </div>
        </>
      ) : (
        <Layout>
          {infoForMobileVersion.map((i) => (
            <div className="img-info__mobile-block" key={i.title}>
              <div className="img-info__img-mobile" data-aos="fade-left" data-aos-duration="800">
                <img src={i.img} alt="photo" />
              </div>
              <div className="img-info__info-mobile">
                <div
                  className="img-info__info-text-mob"
                  data-aos="fade-right"
                  data-aos-duration="800"
                >
                  <div className="img-info__title-mob">{i.title}</div>
                  <div className="img-info__description-mob">{i.desc}</div>
                </div>
              </div>
            </div>
          ))}
        </Layout>
      )}
    </div>
  );
};

export default ImgAndInfo;
