export const TITLE = 'ROADMAP';
export const TITLE1 = 'ORIGIN';
export const TITLE2 = 'MIDGARD AND BEYOND!';
export const TITLE3 = 'VIKING’s HOARD';
export const TITLE4 = 'BATTLE OF THE VIKINGS';

export const ORIGIN_INFO1 = 'Single-player Game development';
export const ORIGIN_INFO2 = 'Community building';
export const ORIGIN_INFO3 = 'First NFT sale 999 Viking Weapons';
export const ORIGIN_INFO4 = 'Storyline Introduced';
export const ORIGIN_INFO5 = '1st Staking';
export const ORIGIN_INFO6 = '1st Community Vote';
export const ORIGIN_INFO7 = 'Tokenomics Revealed';

export const MIDGARD_INFO1 = 'Midgard Viking sale 9999 Vikings';
export const MIDGARD_INFO2 = 'O9W single-player game release';
export const MIDGARD_INFO3 = 'O9W Limited edition comic series Introduced';
export const MIDGARD_INFO4 = 'First Community vote';
export const MIDGARD_INFO5 = 'Multiplayer game released (Midgard)';

export const HOARD_INFO1 = 'Hedeby (In-Game Items Shop)';
export const HOARD_INFO2 = 'Companions revealed';
export const HOARD_INFO3 = 'Lands Utility & Token Payoff';
export const HOARD_INFO4 = '9 Worlds Pyres revealed';

export const BATTLE_INFO1 = 'Players buy Lands in 9 worlds';
export const BATTLE_INFO2 = "Whole nine worlds active with lands & ecosystem's expansion";
export const BATTLE_INFO3 = 'Players daily rewards system established';
export const BATTLE_INFO4 = 'Other Metaverse Integration';
