import React from 'react';
import './CountdownWithButton.scss';
import * as constants from '../../constants';
import topsvg from '../../../../../../global/images/jointhe/Vector 4.svg';
//import weapons from '../../../../../../global/images/presentation/SOLD OUT.svg';
import opensea from '../../../../../../global/images/presentation/OpenSea.svg';
import bottomsvg from '../../../../../../global/images/jointhe/Vector 5.svg';

const CountdownWithButton = () => {
  const handleCLickBtn = () => {
    window.open(constants.BTN2_URL);
  };

  return (
    <div className="countdown__container">
      <div className="countdown__counter">
        <h1 ><a href="https://erlog.odin9worlds.io/" className='erlogtext'>Start playing Erlog</a></h1>
      </div>
      <div className="countdown__container_btn">
        <div className="countdown__button" onClick={handleCLickBtn}>
          <img src={topsvg} alt="topsvg" className="topsvg" />
          <img src={opensea} alt="weapons" className="btn-img" />
          <img src={bottomsvg} alt="bottomsvg" className="bottomsvg" />
        </div>
      </div>
    </div>
  );
};

export default CountdownWithButton;
