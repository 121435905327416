import React, { useState } from 'react';
import arrow from '../../../../../../global/images/fag/arrow.svg';

const QuestionComponent = ({ title, content }) => {
  const [isOpen, setOpen] = useState(false);
  const handleOpen = () => setOpen((prev) => !prev);

  return (
    <div
      className={`fag__question ${isOpen ? `fag__question-opened` : `fag__question-closed`}`}
      key={title}
    >
      <div className="fag__arrow" onClick={handleOpen}>
        <div className="fag__question-title">{title}</div>
        <div className="fag__arrow-block">
          {' '}
          <img src={arrow} alt="arrow" className={`${!isOpen && `fag__question-closed-arrow`}`} />
        </div>
      </div>
      {isOpen && <div className="fag__question-info">{content}</div>}
    </div>
  );
};

export default QuestionComponent;
